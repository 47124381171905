import React, { Component } from "react";

export default class AboutUsSection extends Component {
  render() {
    return (
      <section id="ts-features" className="ts-features">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="ts-intro">
                <h2 className="into-title">About Us</h2>
                <h3 className="into-sub-title">We are making dreams true...</h3>
                <p style={{ textAlign: "justify" }}>
                  <strong>B.M MEMORIAL SCHOOL</strong> was established in{" "}
                  <strong>2016</strong> and it is managed by the{" "}
                  <strong>B.M. Foundation</strong>. It is located at Barkagaon
                  block of Hazaribag district of Jharkhand. The school consists
                  of Grades from <strong>1 to 10</strong> . The school is
                  Co-educational and it also has an attached{" "}
                  <strong>pre-primary</strong> section. English is the medium of
                  instructions in this school. This school is approachable by
                  all weather road. In this school academic session starts in
                  April. The Founder of the school{" "}
                  <strong>Shri Prabhu Dayal Mahto</strong>, Director{" "}
                  <strong>Shri Sandeep Kushwaha</strong> and Co-Director{" "}
                  <strong>Shri Dinker Prasad Kushwaha</strong>.
                </p>
              </div>
              <div className="gap-20"></div>
              <div className="row">
                <div className="col-md-6">
                  <div className="ts-service-box">
                    <span className="ts-service-icon">
                      <i className="fas fa-trophy"></i>
                    </span>
                    <div className="ts-service-box-content">
                      <h3 className="service-box-title">
                        We've Repution for Excellence
                      </h3>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="ts-service-box">
                    <span className="ts-service-icon">
                      <i className="fas fa-sliders-h"></i>
                    </span>
                    <div className="ts-service-box-content">
                      <h3 className="service-box-title">
                        We Build Partnerships
                      </h3>
                    </div>
                  </div>
                </div>
              </div>{" "}
              <div className="row">
                <div className="col-md-6">
                  <div className="ts-service-box">
                    <span className="ts-service-icon">
                      <i
                        className="fas fa-thumbs-up"
                        style={{ color: "green" }}
                      ></i>
                    </span>
                    <div className="ts-service-box-content">
                      <h5
                        className="service-box-title"
                        style={{ color: "green" }}
                      >
                        Green & Clean Environment
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="ts-service-box">
                    <span className="ts-service-icon">
                      <i className="fas fa-users"></i>
                    </span>
                    <div className="ts-service-box-content">
                      <h5 className="service-box-title">
                        A Team of Professional Teachers
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6 mt-5 mt-lg-0">
              <h3 className="into-sub-title">Our Values</h3>
              <div id="page-slider" className="page-slider small-bg">
                <div
                  className="item"
                  style={{
                    backgroundImage: "url(images/slider-pages/growth.jpg)",
                  }}
                >
                  <div className="container">
                    <div className="box-slider-content">
                      <div className="box-slider-text">
                        <h5 className="box-slide-title">
                          Student's Growth & Performance
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="item"
                  style={{
                    backgroundImage: "url(images/slider-pages/confidence3.jpg)",
                  }}
                >
                  <div className="container">
                    <div className="box-slider-content">
                      <div className="box-slider-text">
                        <h5 className="box-slide-title">
                          Student's Self Confidence
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="item"
                  style={{
                    backgroundImage: "url(images/slider-pages/kind.png)",
                  }}
                >
                  <div className="container">
                    <div className="box-slider-content">
                      <div className="box-slider-text">
                        <h5 className="box-slide-title">
                          Kind & Gentle Person
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="item"
                  style={{
                    backgroundImage: "url(images/slider-pages/slide-page3.jpg)",
                  }}
                >
                  <div className="container">
                    <div className="box-slider-content">
                      <div className="box-slider-text">
                        <h5 className="box-slide-title">
                          Student's Leadership Quality
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
