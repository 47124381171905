import React, { Component } from "react";

export default class Carousel extends Component {
  render() {
    return (
      <div className="banner-carousel banner-carousel-1 mb-0">
        <div
          className="banner-carousel-item"
          style={{ backgroundImage: "url(images/slider-main/bmms1.jpeg)" }}
        >
          <div className="slider-content">
            <div className="container h-100">
              <div className="row align-items-center h-100">
                <div className="col-md-12 text-center">
                  <h2 className="slide-title" data-animation-in="slideInLeft">
                    6 Years of excellence
                  </h2>
                  <h3
                    className="slide-sub-title"
                    data-animation-in="slideInRight"
                  >
                    Admission Open!
                  </h3>
                  <p data-animation-in="slideInLeft" data-duration-in="1.2">
                    <a href="index.html" className="slider btn btn-primary">
                      Our Services
                    </a>
                    {/* style="background: rgba(0, 0, 0, 0.55); width: fit-content;" */}
                    <a
                      href="index.html"
                      className="slider btn btn-primary border"
                    >
                      Contact Now
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="banner-carousel-item"
          style={{ backgroundImage: "url(images/slider-main/bmms4.jpg)" }}
        >
          <div className="slider-content text-left">
            <div className="container h-100">
              <div className="row align-items-center h-100">
                <div className="col-md-12">
                  <h2
                    className="slide-title-box"
                    data-animation-in="slideInDown"
                  >
                    Make Future{" "}
                  </h2>
                  <h3 className="slide-title" data-animation-in="fadeIn">
                    With{" "}
                  </h3>
                  <h3
                    className="slide-sub-title"
                    data-animation-in="slideInLeft"
                  >
                    Education & Skill
                  </h3>
                  <p data-animation-in="slideInRight">
                    <a
                      href="index.html"
                      className="slider btn btn-primary border"
                    >
                      Tour Our school
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="banner-carousel-item"
          style={{ backgroundImage: "url(images/slider-main/bmms2.jpeg)" }}
        >
          <div className="slider-content text-right">
            <div className="container h-100">
              <div className="row align-items-center h-100">
                <div className="col-md-12">
                  <h2 className="slide-title" data-animation-in="slideInDown">
                    Join with us
                  </h2>
                  <h3 className="slide-sub-title" data-animation-in="fadeIn">
                    We believe in student
                  </h3>
                  <p
                    className="slider-description lead"
                    data-animation-in="slideInRight"
                  >
                    We will deal with his/her failure and determines how they
                    achieve success.
                  </p>
                  <div data-animation-in="slideInLeft">
                    <a
                      href="index.html"
                      className="slider btn btn-primary"
                      aria-label="contact-with-us"
                    >
                      Contact us
                    </a>
                    <a
                      href="index.html"
                      className="slider btn btn-primary border"
                      aria-label="learn-more-about-us"
                    >
                      Learn more
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
