import React, { Component } from "react";

export default class ServiceSection extends Component {
  render() {
    return (
      <section id="ts-service-area" className="ts-service-area pb-0">
        <div className="container">
          <div className="row text-center">
            <div className="col-12">
              <h2 className="section-title">Our Specialisation</h2>
              <h3 className="section-sub-title">What We Provide</h3>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4">
              <div className="ts-service-box d-flex">
                <div className="ts-service-box-img">
                  <img
                    loading="lazy"
                    src="images/icon-image/business.png"
                    alt="service-icon"
                  />
                </div>
                <div className="ts-service-box-info">
                  <h3 className="service-box-title">
                    <a href="index.html">Trained Teachers</a>
                  </h3>
                  <p>
                    Our Teachers are well-trained from various organizations.
                  </p>
                </div>
              </div>

              <div className="ts-service-box d-flex">
                <div className="ts-service-box-img">
                  <img
                    loading="lazy"
                    src="images/icon-image/classroom.png"
                    alt="service-icon"
                  />
                </div>
                <div className="ts-service-box-info">
                  <h3 className="service-box-title">
                    <a href="index.html">Smart Class Rooms</a>
                  </h3>
                  <p>
                    We provide digital teaching and learning methods for better
                    undersand.
                  </p>
                </div>
              </div>

              <div className="ts-service-box d-flex">
                <div className="ts-service-box-img">
                  <img
                    loading="lazy"
                    src="images/icon-image/laptop.png"
                    alt="service-icon"
                  />
                </div>
                <div className="ts-service-box-info">
                  <h3 className="service-box-title">
                    <a href="index.html">Computer Lab</a>
                  </h3>
                  <p>Acknowledge of Computer with practical.</p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 text-center">
              <img
                loading="lazy"
                className="img-fluid"
                src="images/services/schoolChildStand.jpg"
                alt="service-avater-image"
                style={{ filter: "blur(8px);-webkit-filter: blur(3px)" }}
              />
            </div>

            <div className="col-lg-4 mt-5 mt-lg-0 mb-4 mb-lg-0">
              <div className="ts-service-box d-flex">
                <div className="ts-service-box-img">
                  <img
                    loading="lazy"
                    src="images/icon-image/building.png"
                    alt="service-icon"
                  />
                </div>
                <div className="ts-service-box-info">
                  <h3 className="service-box-title">
                    <a href="index.html">Big Campus</a>
                  </h3>
                  <p>
                    Big Campus is an interactive environment that allows
                    students to overall growth.
                  </p>
                </div>
              </div>

              <div className="ts-service-box d-flex">
                <div className="ts-service-box-img">
                  <img
                    loading="lazy"
                    src="images/icon-image/meeting-room.png"
                    alt="service-icon"
                  />
                </div>
                <div className="ts-service-box-info">
                  <h3 className="service-box-title">
                    <a href="index.html">Airy Class Rooms</a>
                  </h3>
                  <p>
                    B.M.M. School Classrooms are spacious & airy and comfortable
                    wooden furniture.
                  </p>
                </div>
              </div>

              <div className="ts-service-box d-flex">
                <div className="ts-service-box-img">
                  <img
                    loading="lazy"
                    src="images/icon-image/bus.png"
                    alt="service-icon"
                  />
                </div>
                <div className="ts-service-box-info">
                  <h3 className="service-box-title">
                    <a href="index.html">Tranport Facilities</a>
                  </h3>
                  <p>
                    School transportation facility is available for those
                    students who are desirous of availing it.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
