import React, { Component } from "react";

export default class TestinomialSection extends Component {
  render() {
    return (
      <section className="content">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              {/* <h3 className="column-title">.</h3> */}

              <div id="testimonial-slide" className="testimonial-slide">
                <div className="item">
                  <div className="quote-item">
                    <span
                      className="quote-text"
                      style={{ textAlign: "justify" }}
                    >
                      Education is not mere accumulation of facts; it is
                      preparation of life itself. Education is knowledge imbued
                      with wisdom and ethics. It develops the personality of the
                      students, moulds their character and develops mental
                      skills to help them cope with problems and challenges of
                      the complex world of today.
                    </span>

                    <div className="quote-item-footer">
                      <img
                        loading="lazy"
                        className="testimonial-thumb"
                        src="images/clients/sandeep.jpeg"
                        alt="testimonial"
                      />
                      <div className="quote-item-info">
                        <h3 className="quote-author">Sandeep Kushwaha</h3>
                        <span className="quote-subtext">Director</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="item">
                  <div className="quote-item">
                    <span
                      className="quote-text"
                      style={{ textAlign: "justify" }}
                    >
                      B.M. Memorial School provides an environment in which
                      every student discovers and realizes his inborn potential.
                      We create self-awareness amongst children and help in
                      capacity building. We are concerned about the overall
                      health and wellbeing of learners encompassing their
                      mental, physical, emotional and spiritual dimensions.
                    </span>

                    <div className="quote-item-footer">
                      <img
                        loading="lazy"
                        className="testimonial-thumb"
                        src="images/clients/dinker.jpeg"
                        alt="testimonial"
                      />
                      <div className="quote-item-info">
                        <h3 className="quote-author">Dinker Prasad Kushwaha</h3>
                        <span className="quote-subtext">Co-Director</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="item">
                  <div className="quote-item">
                    <span className="quote-text">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor inci done idunt ut labore et dolore
                      magna aliqua. Ut enim ad minim veniam, quis nostrud
                      exercitoa tion ullamco laboris nisi ut commodo consequat.
                    </span>

                    <div className="quote-item-footer">
                      <img
                        loading="lazy"
                        className="testimonial-thumb"
                        src="images/clients/testimonial3.png"
                        alt="testimonial"
                      />
                      <div className="quote-item-info">
                        <h3 className="quote-author">Minter Puchan</h3>
                        <span className="quote-subtext">Director, AKT</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 mt-5 mt-lg-0">
              <h3 className="into-sub-title">News</h3>
              <div id="page-slider" className="page-slider small-bg">
                <div
                  className="item"
                  style={{ backgroundImage: "url(images/news/news1.jpeg)" }}
                >
                  {/* <div className="container">
                    <div className="box-slider-content">
                      <div className="box-slider-text">
                        <h5 className="box-slide-title">
                          Student's Growth & Performance
                        </h5>
                      </div>
                    </div>
                  </div> */}
                </div>

                <div
                  className="item"
                  style={{ backgroundImage: "url(images/news/news3.jpeg)" }}
                >
                  {/* <div className="container">
                    <div className="box-slider-content">
                      <div className="box-slider-text">
                        <h5 className="box-slide-title">
                          Student's Self Confidence
                        </h5>
                      </div>
                    </div>
                  </div> */}
                </div>
                <div
                  className="item"
                  style={{ backgroundImage: "url(images/news/news2.jpeg)" }}
                >
                  {/* <div className="container">
                    <div className="box-slider-content">
                      <div className="box-slider-text">
                        <h5 className="box-slide-title">
                          Kind & Gentle Person
                        </h5>
                      </div>
                    </div>
                  </div> */}
                </div>
                {/* 
                <div
                  className="item"
                  style={{
                    backgroundImage: "url(images/slider-pages/slide-page3.jpg)",
                  }}
                >
                  <div className="container">
                    <div className="box-slider-content">
                      <div className="box-slider-text">
                        <h5 className="box-slide-title">
                          Student's Leadership Quality
                        </h5>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
