import React from "react";

function Navbar() {
  return (
    <header id="header" className="header-one navbar-fixed ">
      <div className="site-navigation ">
        <div className="container">
          {" "}
          <div className="row">
            <div className="col-lg-12">
              {" "}
              <nav className="navbar navbar-expand-lg navbar-dark p-0">
                <div className="logo">
                  <a className="d-block" href="index.html">
                    <img
                      loading="lazy"
                      src="images/bm_logo_original.png"
                      alt="BMMS"
                    />
                  </a>
                </div>
                <li
                  className="header-get-a-quote"
                  style={{ listStyle: "none" }}
                >
                  <a className="btn btn-primary" href="index.html">
                    B.M. MEMORIAL SCHOOL
                  </a>
                </li>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target=".navbar-collapse"
                  aria-controls="navbar-collapse"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>

                <div id="navbar-collapse" className="collapse navbar-collapse">
                  <ul className="nav navbar-nav ml-auto ">
                    <li className="nav-item dropdown active">
                      <a
                        href="https://bmmschool.in/"
                        className="nav-link dropdown-toggle"
                        data-toggle="dropdown"
                      >
                        Home
                      </a>
                      <ul className="dropdown-menu" role="menu">
                        <li className="active">
                          <a href="index.html">Home One</a>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item dropdown">
                      <a
                        href="https://bmmschool.in/"
                        className="nav-link dropdown-toggle"
                        data-toggle="dropdown"
                      >
                        Gallery <i className="fa fa-angle-down"></i>
                      </a>
                      {/* <ul className="dropdown-menu" role="menu">
                        <li>
                          <a href="index.html">Gallery All</a>
                        </li>
                        <li>
                          <a href="index.html">Gallery Single</a>
                        </li>
                      </ul> */}
                    </li>
                    <li className="nav-item dropdown">
                      <a
                        href="https://bmmschool.in/"
                        className="nav-link dropdown-toggle"
                        data-toggle="dropdown"
                      >
                        Admission<i className="fa fa-angle-down"></i>
                      </a>
                      {/* <ul className="dropdown-menu" role="menu">
                        <li>
                          <a href="index.html">Admission</a>
                        </li>
                        <li>
                          <a href="index.html">Admission</a>
                        </li>
                      </ul> */}
                    </li>

                    <li className="nav-item dropdown">
                      <a
                        href="https://bmmschool.in/"
                        className="nav-link dropdown-toggle"
                        data-toggle="dropdown"
                      >
                        News <i className="fa fa-angle-down"></i>
                      </a>
                    </li>

                    <li className="nav-item">
                      <a className="nav-link" href="index.html">
                        Contact
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="https://app.bmmschool.in/bmms/user-pages/login-1"
                        className="nav-link"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Login
                      </a>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Navbar;
