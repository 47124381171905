import React, { Component } from "react";

export default class ContactSection extends Component {
  render() {
    return (
      <section className="subscribe no-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-2">
              <div className="subscribe-call-to-acton">
                <div className="newsletter-introtext">
                  <h3 className="text-white mb-0">Follow us</h3>
                  <img
                    loading="lazy"
                    href="https://www.facebook.com/bmmschool/"
                    src="images/icon-image/facebook-logo-2019.png"
                    alt="sfacebook-icon"
                    target="_blank"
                  />
                </div>
              </div>
            </div>

            <div className="col-md-7">
              <div className="ts-newsletter row align-items-center">
                <div className="col-md-7 newsletter-introtext">
                  <h4 className="text-white mb-0">
                    <i className="fas fa-phone-alt"></i> Contact Here
                  </h4>
                  <p className="text-white">
                    +91-9470510100, +91-9955410000,
                    <br /> +91-78704211111{" "}
                  </p>
                </div>
                <div className="col-md-5 newsletter-introtext">
                  <h4 className="text-white mb-0">
                    <i className="far fa-envelope"></i> Mail Us
                  </h4>
                  <p className="text-white">
                    info@bmmschool.in &nbsp; bmmsbkg@gmail.com
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div
                className="subscribe-call-to-acton"
                style={{ background: "yellowgreen" }}
              >
                <h3>
                  <i className="fab fa-whatsapp"></i> WhatsApp Here
                </h3>
                <h4>9470510100</h4>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
