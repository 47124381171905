import React, { Component } from "react";

export default class GallerySection extends Component {
  render() {
    return (
      <section id="project-area" className="project-area solid-bg">
        <div className="container">
          <div className="row text-center">
            <div className="col-lg-12">
              <h2 className="section-title">Some Memories</h2>
              <h3 className="section-sub-title">Gallery</h3>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="row shuffle-wrapper">
                <div className="col-1 shuffle-sizer"></div>

                <div className="col-lg-4 col-sm-6 shuffle-item">
                  <div className="project-img-container">
                    <a
                      className="gallery-popup"
                      href="index.html"
                      aria-label="project-img"
                    >
                      <img
                        className="img-fluid"
                        src="images/projects/function2.jpg"
                        alt="project-img"
                      />
                      <span className="gallery-icon">
                        <i className="fa fa-plus"></i>
                      </span>
                    </a>
                    <div className="project-item-info">
                      <div className="project-item-info-content">
                        <h3 className="project-item-title">
                          <a>Cultural Program</a>
                        </h3>
                        <p className="project-cat">Commercial, Interiors</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-sm-6 shuffle-item">
                  <div className="project-img-container">
                    <a
                      className="gallery-popup"
                      href="index.html"
                      aria-label="project-img"
                    >
                      <img
                        className="img-fluid"
                        src="images/projects/Smart_class.jpg"
                        alt="project-img"
                      />
                      <span className="gallery-icon">
                        <i className="fa fa-plus"></i>
                      </span>
                    </a>
                    <div className="project-item-info">
                      <div className="project-item-info-content">
                        <h3 className="project-item-title">
                          <a>Smart Class Room</a>
                        </h3>
                        <p className="project-cat">Healthcare</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-sm-6 shuffle-item">
                  <div className="project-img-container">
                    <a
                      className="gallery-popup"
                      href="index.html"
                      aria-label="project-img"
                    >
                      <img
                        className="img-fluid"
                        src="images/projects/Function.jpg"
                        alt="project-img"
                      />
                      <span className="gallery-icon">
                        <i className="fa fa-plus"></i>
                      </span>
                    </a>
                    <div className="project-item-info">
                      <div className="project-item-info-content">
                        <h3 className="project-item-title">
                          <a>Cultural Program</a>
                        </h3>
                        <p className="project-cat">Government</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12">
              <div className="general-btn text-center">
                <a className="btn btn-primary" href="index.html">
                  View All Photos
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
