import React from "react";
import AboutUsSection from "../component/AboutUsSection";
import Carousel from "../component/Carousel";
import ContactSection from "../component/ContactSection";
import GallerySection from "../component/GallerySection";
import Navbar from "../component/Navbar";
import ServiceSection from "../component/ServiceSection";
import TestinomialSection from "../component/TestinomialSection";
import TimeSection from "../component/TimeSection";

function Home() {
  return (
    <>
      <Navbar />
      <Carousel />
      <TimeSection />
      <AboutUsSection />
      <ContactSection />
      <ServiceSection />
      <GallerySection />
      <TestinomialSection />
    </>
  );
}

export default Home;
