import React, { Component } from "react";

export default class TimeSection extends Component {
  render() {
    return (
      <section class="call-to-action-box no-padding">
        <div class="container">
          <div class="action-style-box">
            <div class="row align-items-center">
              <div class="col-md-8 text-center text-md-left">
                <div class="call-to-action-text">
                  <h3 class="action-title">FOR BETTER TOMORROW</h3>
                </div>
              </div>
              <div class="col-md-4 text-center text-md-right mt-3 mt-md-0">
                <div id="body" class="container-fluid">
                  <div id="div1">
                    <p id="time"></p>
                    <p id="date"></p>
                  </div>
                </div>
                {/* <div class="call-to-action-btn">
                  <a class="btn btn-dark" href="#">
                    Request Quote
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
